import React, { useEffect, useRef, useState } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import "./style.scss";

// import 'react-pro-sidebar/dist/css/styles.css';

import Header from "../Header";
import { HomeIcon } from "../../common/icons/homeIcon";
import { ShipmentIcon } from "../../common/icons/ShipmentIcon";
import { EstimationIcon } from "../../common/icons/EstimationIcon";
import { WalletIcon } from "../../common/icons/WalletIcon";
import { SettingIcon } from "../../common/icons/SettingIcon";
import { GetHelpIcon } from "../../common/icons/GetHelpIcon";
import Home from "./Home";
import { WarehouseIcon } from "../../common/icons/WarehouseIcon";
import Warehouses from "./Warehouses";
import Shipments from "./Shipments";
import Shipments_v2 from "./Shipments/shipments_v2";
import Estimation from "./Estimation";
import Wallet from "./Wallet";
import Modal from "../../common/Modal";
import { Button } from "../../common/core/Button";
import { Dropdown } from "../../common/core/Dropdown";
import { callApi } from "../../utils/api";
import Warehouses_v2 from "./Warehouses/warehouses_v2";
import DeliveryWarehouses_v2 from "./DeliveryLocation/warehouses_v2";
import TrackingPage from "./Warehouses/Timeline";
import CustomInput from "../../common/core/CustomInput";
import { store } from "../../store";
import { PickupIcon } from "../../common/icons/PickupIcon";
import Pickup_v2 from "./Pickup/pickup_v2";
import { DeliveryLocationIcon } from "../../common/icons/DeliveryLocationIcon";
import { addUserData, callForPickup, userLogin } from "../../store/actions";
import { MISIcon } from "../../common/icons/MISIcon";
import Mis from "./MIS/mis_v2";

function Dashboard() {
  const [activeComponent, setActiveComponent] = useState("dashboard");
  const [shippingData, setShippingData] = useState({});

  React.useEffect(()=>{
    (async()=>{
      //alert(JSON.stringify(store.getState().userData))
      await callApi({
        method: "POST",
        endpoint: "api/users/login",
        data: {
          email: store.getState().userData.user?.email,
          password: store.getState().userData.user?.password,
        },
      })
        .then((data) => {
          store.dispatch(addUserData(data));
          store.dispatch(userLogin({token: data.user._id}));
          // localStorage.setItem('userId', data?.userId)
         
        })
        .catch((err) => {
        });
    })();
  },[])
  const renderActiveComponent = () => {
    switch (activeComponent) {
      case "dashboard":
        return (
          <>
            <Home />
          </>
        );
      case "warehouses":
        return (
          <>
            <Warehouses_v2 />
          </>
        );
      case "delivery":
        return (
          <>
            <DeliveryWarehouses_v2 />
          </>
        );
      case "pickups":
        return (
          <>
          <Pickup_v2/>
          </>
        )
      case "shipments":
        return (
          <>
            <Shipments_v2 setActiveComponent={setActiveComponent} setShippingData={setShippingData}/>
          </>
        );
      case "mis":
        return (
          <>
            <Mis />
          </>
        );
      case "estimation":
        return (
          <>
            <Estimation />
          </>
        );
      case "myWallet":
        return (
          <>
            <Wallet />
          </>
        );
      case "timeline":
        return (
          <>
            <TrackingPage setActiveComponent={setActiveComponent} shippingData={shippingData}/>
          </>
        );
      case "settings":
        return <>settings</>;
      default:
        return null;
    }
  };
  const modalRef = useRef();

  const handleOpenModal = () => {
    modalRef.current.openModal();
  };

  const handleCloseModal = () => {
    modalRef.current.closeModal();
  };
  const [warehouseOptionsId, setWarehouseOptionsId] = useState([]);
  const [warehouseOptions, setWarehouseOptions] = useState([]);
  const [warehouseSelected, setWarehouseSelected] =
    useState("Select Warehouse");
  const [no_of_boxes, setNoOfBoxes] = useState("");
  function getCurrentDate() {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    
    return `${year}-${month}-${day}`;
  }
  const [date, setDate] = useState(getCurrentDate());
  React.useEffect(()=>{
    setDate(getCurrentDate())
  }, [])
  const [isVisible, setIsVisible] = useState(false);

  // Reference to the component to check for outside clicks
  const componentRef = useRef(null);

  // Toggle the visibility of the component
  const toggleVisibility = () => setIsVisible(!isVisible);

  // Hide the component
  const hideComponent = () => setIsVisible(false);

  // Handle outside clicks
  const handleClickOutside = (event) => {
    if (componentRef.current && !componentRef.current.contains(event.target)) {
      hideComponent();
    }
  };

  // Add and remove event listener for outside clicks
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);
  const getWarehouses = async () => {
    await callApi({
      method: "GET",
      endpoint: "api/warehouses",
    })
      .then((res) => {
        console.log(res, "org");
        let wList = res.data.map((org) => {
          return org.warehouse_name;
        });

        let wListId = res.data.map((org) => {
          return org._id;
        });
        console.log(wList, "WAREHOUSE LIST");
        setWarehouseOptions(wList);
        setWarehouseOptionsId(wListId);
      })
      .catch(() => {});
  };
  React.useEffect(() => {
    getWarehouses();
  }, []);
  return (
    <>
      {isVisible && (
        <div
          className="card"
          ref={componentRef}
          style={{ position: "fixed", bottom: 20, left: 230, right: 0 }}
        >
          <div className="bg" style={{ padding: 10 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <h2 style={{ color: "#707070" }}>Contact Us</h2>
              <button
                onClick={toggleVisibility}
                style={{
                  cursor: "pointer",
                  border: "none",
                  background: "transparent",
                  fontSize: "14px",
                }}
              >
                x
              </button>
            </div>
            <ul style={{ fontSize: 14 }}>
              <li>Phone:</li>
              <li>+1 234 567 890</li>
              <br />
              <li>Email:</li>
              <li>contact@example.com</li>
            </ul>
          </div>
          <div className="blob"></div>
        </div>
      )}
      <Header login={true} />
      <Modal ref={modalRef}>
        <h4>Request for Pickup</h4>
        <br />
        <div style={{ display: "flex" }}>
          <Dropdown
            inputLike={true}
            optionName={""}
            options={warehouseOptions}
            selectedOption={warehouseSelected}
            setSelectedOption={setWarehouseSelected}
          />
        </div>
        <br />
          <CustomInput
            type={"date"}
            placeholder={"Pickup Date"}
            value={date}
            onChange={(e) => {
              setDate(e.target.value);
            }}
          />
          <br/>
        <CustomInput
          placeholder={"No of Boxes"}
          value={no_of_boxes}
          onChange={(e) => {
            setNoOfBoxes(parseInt(e.target.value));
          }}
        />
        <br />
        <div
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            text={"Create New Pickup"}
            onClick={() => {
              (async () => {
                const dataObject = {
                  user_id: store.getState().userData?.user?.oid,
                  'warehouse_id': warehouseOptionsId[warehouseOptions.indexOf(warehouseSelected)],
                 noOfBoxes: no_of_boxes, pickup_date: date,
       
                }
                await callApi({
                  method: "POST",
                  endpoint: "api/pickups",
                  data: dataObject,
                  alert: true
                })
                  .then((res) => {
                    store.dispatch(callForPickup())
                    handleCloseModal()
                  })
                  .catch((err) => {});
              })();
            }}
          ></Button>
        </div>
      </Modal>
      <div className="dashboard-component">
        {/* Sidebar */}
        <Sidebar>
          <Menu iconShape="square">
            <button
              className="add-button"
              onClick={() => {
                getWarehouses()
                modalRef.current.openModal();
              }}
            >
              + New Pickup
            </button>
            <MenuItem
              onClick={() => setActiveComponent("dashboard")}
              prefix={
                <HomeIcon
                  color={activeComponent === "dashboard" ? "#0056b3" : "#474747"}
                />
              }
              
              className={
                "menu-button " +
                (activeComponent === "dashboard" ? "active-menu-button" : "")
              }
            >
              Home
            </MenuItem>
            <MenuItem
              onClick={() => setActiveComponent("warehouses")}
              prefix={
                <WarehouseIcon
                  color={activeComponent === "warehouses" ? "#0056b3" : "#474747"}
                />
              }
              className={
                "menu-button " +
                (activeComponent === "warehouses" ? "active-menu-button" : "")
              }
            >
              Warehouses
            </MenuItem>{" "}
            

            <MenuItem
              onClick={() => setActiveComponent("delivery")}
              prefix={
                <DeliveryLocationIcon
                  color={activeComponent === "delivery" ? "#0056b3" : "#474747"}
                />
              }
              className={
                "menu-button " +
                (activeComponent === "delivery" ? "active-menu-button" : "")
              }
            >
              Delivery Locations
            </MenuItem>{" "}
            <MenuItem
              onClick={() => setActiveComponent("pickups")}
              prefix={
                <PickupIcon
                  color={activeComponent === "pickups" ? "#0056b3" : "#474747"}
                />
              }
              className={
                "menu-button " +
                (activeComponent === "pickups" ? "active-menu-button" : "")
              }
            >
              Pickups
            </MenuItem>{" "}
            <MenuItem
              onClick={() => setActiveComponent("shipments")}
              prefix={
                <ShipmentIcon
                  color={activeComponent === "shipments" ? "#0056b3" : "#474747"}
                />
              }
              className={
                "menu-button " +
                (activeComponent === "shipments" ? "active-menu-button" : "")
              }
            >
              Shipments
            </MenuItem>
            <MenuItem
              onClick={() => setActiveComponent("mis")}
              prefix={
                <MISIcon
                  color={activeComponent === "mis" ? "#0056b3" : "#474747"}
                />
              }
              className={
                "menu-button " +
                (activeComponent === "mis" ? "active-menu-button" : "")
              }
            >
              MIS
            </MenuItem>
            <MenuItem
              onClick={() => setActiveComponent("estimation")}
              prefix={
                <EstimationIcon
                  color={activeComponent === "estimation" ? "#0056b3" : "#474747"}
                />
              }
              className={
                "menu-button " +
                (activeComponent === "estimation" ? "active-menu-button" : "")
              }
            >
              Estimation
            </MenuItem>
            <MenuItem
              onClick={() => setActiveComponent("myWallet")}
              prefix={
                <WalletIcon
                  color={activeComponent === "myWallet" ? "#0056b3" : "#474747"}
                />
              }
              className={
                "menu-button " +
                (activeComponent === "myWallet" ? "active-menu-button" : "")
              }
            >
              My Wallet
            </MenuItem>
            <div className="sidebar-footer">
              <MenuItem
                onClick={() => {
                  toggleVisibility();
                }}
                prefix={
                  <GetHelpIcon
                    color={activeComponent === "gethelp" ? "#0056b3" : "#474747"}
                  />
                }
                className={
                  "menu-button " +
                  (activeComponent === "gethelp" ? "active-menu-button" : "")
                }
              >
                Get Help
              </MenuItem>
            </div>
          </Menu>
        </Sidebar>

        {/* Main content */}
        <div style={{ flex: 1 }}>{renderActiveComponent()}</div>
      </div>
    </>
  );
}

export default Dashboard;
