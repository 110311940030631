/* LIBRARIES */
import { applyMiddleware, compose, createStore } from "redux";
import { encryptTransform } from "redux-persist-transform-encrypt";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

/* ABSOLUTE IMPORTS */
import rootReducer from "./reducers";

/* MIDDLEWARE IMPORTS */
// import { snifferMiddleware } from "./sniffer";

// persistConfig can have several keys: key: this value will define what will be the key that we will use as identifier to save the persisted information.
const persistConfig = {
  key: "root",
  storage,
  whitelist: ['auth', 'userData'], 
  // devTools: process.env.REACT_APP_ENV !== "production",
  transforms:
    process.env.REACT_APP_ENCRYPT === "true"
      ? [
          encryptTransform({
            secretKey: process.env.REACT_APP_SECRET,
            onError: function (error) {
              //console.log("error occurred", error);
            },
          }),
        ]
      : [],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
let store = {};

if (process.env.REACT_APP_ENV !== "production") {
  // returns an enhanced reducer that wraps the rootReducer you pass in and will persist that reducer's state according to the config you pass in.
  store = createStore(
    persistedReducer,
    // compose(applyMiddleware(snifferMiddleware))
    // window.__REDUX_DEVTOOLS_EXTENSION__ &&
    //   window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true })
  );
} else {
  store = createStore(
    persistedReducer,
    // compose(applyMiddleware(snifferMiddleware))
  );
}
let persistor = persistStore(store);
// persistor.purge();
export { store, persistor };
