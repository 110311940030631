import React from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import Login from "./Components/Login";
import { stateConnected } from "./utils/redux_tools";
import SignUp from "./Components/SignUp";
import WorkspaceBuilder from "./Components/WorkspaceBuilder";
import OTPVerify from "./Components/OTPVerify";
import Dashboard from "./Components/Dashboard";
import FileManager from "./Components/Dashboard/FileManager";

const Routing = (props) => {
  
  return (
    <div>
      {!props.auth?.token ? (
        <Routes>
          <Route path="/" element={<Login />} />
          <Route exact path="/signup" element={<SignUp />} />
          <Route exact path="/verify" element={<OTPVerify />} />
        <Route exact path="/workspace" element={<WorkspaceBuilder />} />
        <Route exact path="/file-manager" element={<FileManager />} />
        <Route path="*" element={<Login />} />
        </Routes>
      ) : props.auth?.token ? (
        <Routes>
          <Route
            path="/"
            element={
              <Dashboard/>
            }
          />
          <Route exact path="/file-manager" element={<FileManager />} />
          {/* <Route
            path="/warehouses"
            element={
              <Sidebar>
                <Location />
              </Sidebar>
            }
          />
          <Route
            path="/shipments"
            element={
              <Sidebar>
                <Aws />
              </Sidebar>
            }
          />
          <Route
            path="/estimator"
            element={
              <Sidebar>
                <Display />
              </Sidebar>
            }
          /> */}
          {/* <Route
            path="/wallet"
            element={
              <Sidebar>
                <Users />
              </Sidebar>
            }
          /> */}
        </Routes>
      ) : (
        <Navigate to="/login" />
      )}
    </div>
  );
};

export default stateConnected(Routing);