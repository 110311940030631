import { ADD_USER_DATA, UPDATE_PICKUP, LOG_IN, LOG_OUT, UPDATE_CONFIGURATIONS, UPDATE_PIPELINE_DATA, UPDATE_WOPIPE_DATA, TOGGLE_THEME, INIT_DATA } from "../../common/constant/strings";

/**
 * @returns action object which returns -> initialData for dipatch
 */
export const initData = () => {
  return {
    type: INIT_DATA,
    value: null,
  };
};


/**
 * @param {data}  contains the value for "value"
 * @returns action object which returns ->{ type , value } for dipatch
 */
export const userLogin = (data) => {
  return {
    type: LOG_IN,
    value: data,
  };
};

/**
 * @param {data of the user } data 
 * @returns action object which returns ->{ type , value } for dipatch
 */
export const addUserData = (data) => {
  return {
    type: ADD_USER_DATA,
    value: data,
  };
};

/**
 * @param {data for configs} data 
 * @returns action object which returns ->{ type , value } for dipatch
 */
export const addConfigData = (data) => {
  return {
    type: UPDATE_CONFIGURATIONS,
    value: data,
  };
};

/**
 * @param {data for configs} data 
 * @returns action object which returns ->{ type , value } for dipatch
 */
export const callForPickup = (data) => {
  return {
    type: UPDATE_PICKUP,
    value: data,
  };
};
/**
 * @returns action object which returns ->{ type , value } for dipatch
 */
export const logout = () => {
  return {
    type: LOG_OUT,
    value: null,
  };
};

export const toggleTheme = (data) => {
  return {
    type: TOGGLE_THEME,
    value: data,
  }
};