import React, { useRef, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Header from "../Header";
import loginVector from "../../Images/lb-dash-image.png";
import "./style.scss";
import Alert from "../../utils/Alert";
import ToolTip from "../../utils/Tooltip";
import { callApi } from "../../utils/api";
import { create, first, isEmpty, last } from "lodash";
import Error from "../../utils/Error";
import LoaderComponent from "../../common/Loader";

function SignUp() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [registered, setRegistered] = useState(false);
  const [termsCheck, setTermsCheck] = useState(false);
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState("");
  const [otp, setOTP] = useState("");
  const [otpSent, setOTPSent] = useState("");
  const navigate = useNavigate();

  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const phoneRef = useRef(null);
  const otpRef = useRef(null);
  const passwordConfirmRef = useRef(null);

  function isValidEmail(email) {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailPattern.test(email);
  }

  const registerUser = () => {
    if (isEmpty(firstName)) {
      Error("First Name is Empty!");
      firstNameRef.current.focus();
      firstNameRef.current.classList.add("error-border");
      return;
    }

    if (isEmpty(lastName)) {
      Error("Last Name is Empty!");
      lastNameRef.current.focus();
      lastNameRef.current.classList.add("error-border");
      return;
    }

    if (isEmpty(email)) {
      Error("Email is Empty!");
      emailRef.current.focus();
      emailRef.current.classList.add("error-border");
      return;
    }

    if (!isValidEmail(email)) {
      Error("Enter a email address!");
      emailRef.current.focus();
      emailRef.current.classList.add("error-border");
      return;
    }

    if (isEmpty(password)) {
      Error("Password is Empty!");
      passwordRef.current.focus();
      passwordRef.current.classList.add("error-border");
      return;
    }

    if (password.length < 8) {
      Error("Password must be more than 8 digits!");
      passwordRef.current.focus();
      passwordRef.current.classList.add("error-border");
      return;
    }

    if (isEmpty(passwordConfirm)) {
      Error("Confirm Password is Empty!");
      passwordConfirmRef.current.focus();
      passwordConfirmRef.current.classList.add("error-border");
      return;
    }

    if (password !== passwordConfirm) {
      Error("Password must be equal to Confirm Password!");
      passwordConfirmRef.current.focus();
      passwordConfirmRef.current.classList.add("error-border");
      return;
    }
    console.log('worked here...')
    callApi({
      method: "POST",
      endpoint: "api/users",
      data: {
        first_name: firstName,
        last_name: lastName,
        email: email,
        password: password,
        confirm_password: passwordConfirm,
      },
      alert: true,
    })
      .then(() => {
        navigate(`/verify?n=${email}`)
      })
      .catch(() => {});
  };

  return (
    <div className="App">
      <Header />

      <LoaderComponent
        loader={loading}
        loaderLabel={"Please Wait..."}
      >

      </LoaderComponent>
      <div className="content-wrapper">
        <div className="content-left-section">
        
            <>
              <h1>Sign Up to Logistique Biz</h1>
              <span>
                Simplify Your Logistics Operations with Our App Efficiently
                manage shipments, track deliveries, and optimize routes.
              </span>
              <br />
              <br />

              <div className="two-inputs">
                <div className="two-inputs-section">
                  <label className="input-label">First Name <span className="asterisk-span">*</span></label>
                  <input
                    className="login-input-half"
                    placeholder="John"
                    value={firstName}
                    ref={firstNameRef}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                      if (!isEmpty(e.target.value)) {
                        firstNameRef.current.classList.remove("error-border");
                      }
                    }}
                  ></input>
                </div>
                <div className="two-inputs-section">
                  <label className="input-label">Last Name <span className="asterisk-span">*</span></label>
                  <input
                    className="login-input-half"
                    placeholder="Doe"
                    value={lastName}
                    ref={lastNameRef}
                    onChange={(e) => {
                      setLastName(e.target.value);
                      if (!isEmpty(e.target.value)) {
                        lastNameRef.current.classList.remove("error-border");
                      }
                    }}
                  ></input>
                </div>
              </div>
              <label className="input-label">Email Address <span className="asterisk-span">*</span></label>
              <input
                className="login-input"
                placeholder="john.doe@company.xyz"
                value={email}
                ref={emailRef}
                onChange={(e) => {
                  setEmail(e.target.value);
                  if (
                    !isEmpty(e.target.value) &&
                    isValidEmail(e.target.value)
                  ) {
                    emailRef.current.classList.remove("error-border");
                  }
                }}
              ></input>
              <label className="input-label">Password <span className="asterisk-span">*</span></label>
              <input
                className="login-input"
                placeholder="********"
                type="password"
                value={password}
                ref={passwordRef}
                onChange={(e) => {
                  setPassword(e.target.value);
                  if (!isEmpty(e.target.value) && password.length >= 7) {
                    passwordRef.current.classList.remove("error-border");
                  }
                }}
              ></input>
              <label className="input-label">Confirm Password <span className="asterisk-span">*</span></label>
              <input
                className="login-input"
                placeholder="********"
                type="password"
                value={passwordConfirm}
                ref={passwordConfirmRef}
                onChange={(e) => {
                  setPasswordConfirm(e.target.value);
                  if (!isEmpty(e.target.value) && password === e.target.value) {
                    passwordConfirmRef.current.classList.remove("error-border");
                  }
                }}
              ></input>
              <div className="login-options">
                <label>
                  <input
                    type="checkbox"
                    checked={termsCheck}
                    onClick={() => {
                      setTermsCheck(!termsCheck);
                    }}
                  />{" "}
                  accept the <a>Terms and Condition</a>
                </label>
              </div>
              {!termsCheck ? (
                <>
                  <button
                    disabled={!termsCheck}
                    style={{
                      opacity: 0.1,
                    }}
                  >
                    Register
                  </button>
                </>
              ) : (
                <>
                  <button
                    disabled={!termsCheck}
                    onClick={() => {
                      registerUser();
                    }}
                  >
                    Register
                  </button>
                </>
              )}

              <span>
                Already a user? <a onClick={()=>{
              navigate('/login')
            }}>Log In</a>
              </span>
            </>
         
        </div>
        <div className="content-right-section">
          <img src={loginVector} height={600}></img>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
