import React, { useState } from "react";
import "./style.scss"; // Import the SCSS file for styling
import InputWithIcon from "../../common/core/Input";
import Avatar from "react-avatar";
import { useNavigate } from "react-router-dom";
import { DownArrow } from "../../common/icons/DownArrow";
import { NotificationIcon } from "../../common/icons/NotificationIcon";
import { stateConnected } from "../../utils/redux_tools";
import { callApi } from "../../utils/api";
import { store } from "../../store";

function DesiredDateTime(props) {
  // Create a new Date object for October 1, 2022, at 2:34:45 AM UTC
  const desiredDate = new Date();

  // Get the desired date and time components
  const year = desiredDate.getUTCFullYear();
  const month = desiredDate.getUTCMonth() + 1; // Adding 1 to the month since it's zero-based
  const day = desiredDate.getUTCDate();
  const hours = desiredDate.getUTCHours();
  const minutes = desiredDate.getUTCMinutes();
  const seconds = desiredDate.getUTCSeconds();

  return (
    <></>
    // // <div style={{fontSize: '14px'}}>
    //   {/* {year}-{month}-{day} {hours}:{minutes}:{seconds} <b>UTC</b> */}
    // // </div>
  );
}

const Profile = ({ userData, avatar, onLogout }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLogout = () => {
    // Perform logout logic, such as clearing session/local storage, redirecting, etc.
    onLogout();
  };

  return (
    <div className="relative">
      <button
        className="bg-blue-500 text-white font-bold py-2 px-4 rounded"
        onClick={toggleDropdown}
      >
        {avatar}
      </button>
      {isDropdownOpen && (
        <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg">
          <div className="py-1" style={{padding: 12, alignItems: 'flex-start', justifyContent: 'flex-start', textAlign: 'left'}}>
            {userData?.email}<br/>
           <strong> {userData?.company_id?.company_name} </strong>
          </div>
          <div className="py-1">
            <button
              onClick={handleLogout}
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
            >
              Logout
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
function Header(props) {
  const navigate = useNavigate();
  const [transactionData, setTransactionData] = React.useState([]);
  React.useEffect(() => {
    (async () => {
      await callApi({
        endpoint: "api/transaction",
      })
        .then((res) => {
          setTransactionData(res.data);
        })
        .catch((err) => {});
    })();
  }, []);
  return (
    <header
      className="header"
      style={{
        backgroundColor: transactionData?.[0]?.wallet_balance
          ? transactionData[0].wallet_balance <
            store.getState().userData?.user?.company_id?.minimium_balance
            ? "rgba(255,0,0, 0.8)"
            : "transparent"
          : "transparent",
      }}
    >
      <div>
        <img
          src={require("../../Images/logo.png")}
          height={50}
          width={50}
          style={{ borderRadius: 500, marginLeft: -10 }}
        ></img>
        {/* <h1>Logistique Biz</h1> */}
      </div>
      <div>{/* <InputWithIcon placeholder={"Search for location"} /> */}</div>
      {props.login ? (
        <>
          <div
            className="nav-buttons"
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <span
              style={{
                marginRight: "40px",
                fontSize: "18px",
                fontWeight: "400",
                color: transactionData?.[0]?.wallet_balance
                  ? transactionData[0].wallet_balance <
                    store.getState().userData?.user?.company_id
                      ?.minimium_balance
                    ? "white"
                    : "black"
                  : "black",
              }}
            >
              {transactionData?.[0]?.wallet_balance
                ? transactionData[0].wallet_balance <
                  store.getState().userData?.user?.company_id?.minimium_balance
                  ? "Low Wallet Balance:"
                  : "Wallet Amount:"
                : "Wallet Amount:"}{" "}
              ₹{" "}
              {transactionData?.[0]?.wallet_balance
                ? transactionData[0].wallet_balance
                : "0"}
            </span>

            <NotificationIcon />

            <div style={{ marginRight: "20px" }}></div>
            <Profile
              userData={props?.userData?.user}
              avatar={
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {/* {JSON.stringify(props.userData?.user)} */}
                  <Avatar
                    name={
                      props.userData?.user?.first_name +
                      " " +
                      props.userData?.user?.last_name
                    }
                    size="30"
                    round={true}
                  />{" "}
                  <span
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <DownArrow />
                  </span>
                </div>
              }
              onLogout={() => {
                localStorage.clear();
                window.location.reload();
              }}
            />
          </div>
        </>
      ) : (
        <div className="nav-buttons">
          <button
            onClick={() => {
              navigate("/");
            }}
          >
            Login
          </button>
          <button
            className="green-button"
            onClick={() => {
              navigate("/signup");
            }}
          >
            Register
          </button>
        </div>
      )}
    </header>
  );
}

export default stateConnected(Header);
