/* LIBRARIES */
import { omit, cloneDeep } from "lodash";
import {INIT_DATA, LOG_IN, ADD_USER_DATA, UPDATE_PICKUP} from '../../common/constant/strings'
/* ABSOLUTE IMPORTS */

function keyGenerate(length) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

// This lets reducers specify initial data that makes sense to them as default arguments,
const initialState = {
  auth: {
    dns: null,
    token: null,
  },
  userData: {},
  configs: {
    key: keyGenerate(18),
  },
  callForPickup: false

};

/**
 * @param {object} state  initialState
 * @param {object} action contains type, value
 * @returns takes an action and the previous state and returns the new state.
 * The action describes what happened and reducer's job is to return the new state based on action.
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_DATA:
      return initialState;

    case LOG_IN:
      return { ...state, auth: { ...state.auth, ...action.value } };

    case ADD_USER_DATA:
      return { ...state, userData: action.value };
    case UPDATE_PICKUP:
      return {...state, callForPickup: !state.callForPickup}
    default:
      return state;
  }
};

export default reducer;
