import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Header from "../Header";
import loginVector  from '../../Images/lb-dash-image.png'
import "./style.scss";
import { callApi } from "../../utils/api";
import { isEmpty } from "lodash";
import Loader from "../../common/Loader";
import Alert from "../../utils/Alert";
import { store } from "../../store";
import { addUserData, userLogin } from "../../store/actions";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  const emailRef = useRef(null);
  const passwordRef = useRef(null);

  const handleLogin = async() => {
    if (isEmpty(email)) {
      Error("Email is Empty!");
      emailRef.current.focus();
      emailRef.current.classList.add("error-border");
      return;
    }

    if (isEmpty(password)) {
      Error("Password is Empty!");
      passwordRef.current.focus();
      passwordRef.current.classList.add("error-border");
      return;
    }
    setLoading(true)
    await callApi({
      method: "POST",
      endpoint: "api/users/login",
      data: {
        email: email,
        password: password,
      },
      alert: true,
    })
      .then((data) => {
        console.log('DATA----', data)
        store.dispatch(addUserData(data));
        store.dispatch(userLogin({token: data.user._id}));
        // localStorage.setItem('userId', data?.userId)
        setTimeout(()=>{
          setLoading(false)
          navigate('/')
        }, 1000);
      })
      .catch((err) => {
        setLoading(false)
        if(err?.ui){
          if(err.ui === "phone"){
            navigate(`/verify?n=${email}`)
          }else if (err.ui === "workspace"){
            navigate(`/workspace?n=${email}`)
          }
        }
      });
  };

  return (
    <div className="App">
      <Header />
      <Loader
        loader={loading}
        loaderLabel={"Please Wait..."}
      >
      </Loader>
      <div className="content-wrapper">
        <div className="content-left-section">
          <h1>Login to Logistique Biz</h1>
          <span>
            Simplify Your Logistics Operations with Our App Efficiently manage
            shipments, track deliveries, and optimize routes.
          </span>
          <br />
          <br />
          <input
            className="login-input"
            placeholder="john.doe@company.xyz"
            value={email}
            ref={emailRef}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                handleLogin();
              }
            }}
            onChange={(e) => {
              setEmail(e.target.value);
              if (
                !isEmpty(e.target.value)
              ) {
                emailRef.current.classList.remove("error-border");
              }
            }}
          ></input>
          <input
            className="login-input"
            placeholder="********"
            type="password"
            value={password}
            ref={passwordRef}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                handleLogin();
              }
            }}
            onChange={(e) => {
              setPassword(e.target.value);
              if (
                !isEmpty(e.target.value)
              ) {
                passwordRef.current.classList.remove("error-border");
              }
            }}
          ></input>
          <div className="login-options">
            {/* <label>
              <input
                type="checkbox"
              />
              Remember Me
            </label> */}
            {/* <a>Forgot Password?</a> */}
          </div>
          <button onClick={()=>{
            handleLogin();
          }}>Login</button>
          <span>
            Do not have an Account yet? <a onClick={()=>{
              navigate('/signup')
            }}>Register now</a>
          </span>
        </div>
        <div className="content-right-section">
            <img src={loginVector} height={600}>
            </img>
        </div>
      </div>
    </div>
  );
}

export default Login;
