import React from "react";
import "./style.scss";

function NoRecords() {
  return (
    <div className="no-records-wrapper">
      <div className="no-records">No Records
      </div>
      <div>Add new records by clicking on Add New +</div>
    </div>
  );
}

export default NoRecords;
