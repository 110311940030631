/**
 * @returns if the user login bypass is true or not
 */
export const isLoginByPass = () => {
  return process.env.REACT_APP_LOGIN_BYPASS == "true";
};

/**
 * @returns if the debug mode is true or not
 */
export const isDebugMode = () => {
  return process.env.REACT_APP_DEBUG === "true";
};

/**
 * @returns the base URL
 */
export const getBaseURL = () => {
  return "https://backend-matrimony.ndm.ai/?url=";
  return process.env.REACT_APP_IP + ":" + process.env.REACT_APP_PORT;
};
